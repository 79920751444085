<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">构建体系信息流</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        要构建体系信息流，首先就要构建管理体系的路线图，如某制造企业的体系路线图的实例：
      </p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/6.png"
        class="SonImgW"
      />
      <p class="SmallP">某服务企业的经营路线图的实例：</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/7.png"
        class="SonImgW"
      />
      <p class="SmallP">
        经营路线图所展示的过程一旦被确定便成为组织的“常规过程”。
      </p>
      <p class="SmallP">以此原理展开到部门体系路线图，如：</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/8.png"
        class="SonImgW"
      />
      <p class="SmallP">
        经营路线图是应用“过程方法”建立起来的，在连接线上加上数字编号就代表了信息流中输出的编号，如下图：
      </p>
      <div class="ImgP">
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/9.png"
          class="SonImgW"
        />
        <p class="SmallP">图1-11</p>
      </div>
      <p class="SmallP">从上图中建立信息流索引图，如下图：</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/10.png"
        class="SonImgW"
      />
      <p class="SmallP">
        或者采用下面的表达形式，其原理就是一个过程的输出会成为下一过程的输入。
      </p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/11.png"
        class="SonImg"
      />
      <p class="SmallP">在数字化的操作中，通常采用软件去实现，如：</p>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/12.png"
        class="SonImgW"
      />
      <br />
      <img @click="openWindow" src="@/assets/FirstPage-ProductSon/13.png" />
      <p class="SmallP">
        数字编号作为信息流的“索引”。信息流索引置于后台以方便点对点和端对端的对接。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>